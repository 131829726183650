.slider {
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
}

.image {
  width: auto;
  height: 37.5rem;
  border-radius: 0.625rem;
}

.rightArrow,
.leftArrow {
    position: absolute;
    top: 50%;
    font-size: 3rem;
    color: #fff;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    transform: translateY(-50%)
  }

  .leftArrow {
    left: 2rem;
  }

  .rightArrow {
    right: 2rem;
  }

  .slide {
    opacity: 0;
    transition-duration: 1s ease;
    justify-content: center;
  }

  .activeSlide {
    opacity: 1;
    transition-duration: 1s;
    justify-content: center;
  }

  .dot {
    margin: 0 .25rem;
    cursor: pointer;
    color: #fff;
    font-size: 1.25rem;
  }

  .activeDot {
    transform: scale(1.5);
  }

  .dimDot {
    opacity: 0.6;
  }

  .dotsContainer {
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1rem;
  }

  .caption {
    color: #fff;
    justify-content: center;
    text-align: center;
  }

  @media (max-width: 768px) {
    .rightArrow, .leftArrow {
      font-size: 2rem; /* Reduce the size of the arrows */
    }

    .rightArrow {
      right: 1rem;
    }

    .leftArrow {
      left: 1rem;
    }
  
    .dot {
      font-size: 1rem; /* Reduce the size of the dots */
    }
  }