.pages {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.leftArrow,
.rightArrow {
    font-size: 1.5rem;
}

.leftArrow {
    margin-right: 0.25rem;
}

.label {
    font-family: 'robotoFlexVar';
    font-size: 1.125rem;
}

.rightArrow {
    margin-left: 0.25rem;
}

.leftArrow:hover,
.rightArrow:hover {
    transform: scale(1.1);
}