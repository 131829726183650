.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 90%;
  margin: 0 auto;
  padding: 1.25rem;
  border: 1px solid #ccc;
  border-radius: 1.25rem;
  background-color: #f9f9f9;
  box-shadow: 0.375rem 0.375rem 0 0.25rem #005180;
  font-family: 'robotoFlexVar';
}

.form input {
  max-width: 100%;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
}

textarea {
  width: 100%;
  border: 1px solid #ccc;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
  border-radius: 0.375rem;
  resize: vertical;
  box-sizing: border-box;
  font-family: 'robotoFlexVar';
}

.form .submit {
  display: block;
  width: 100%;
  padding: 0.625rem;
  background-color: #20bdff;
  color: #fff;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  margin-bottom: 0;
}

.form h3 {
  font-family: 'robotoFlexVar';
  font-size: 1.125rem;
  color: #333;
  margin: 0 0 0.625rem 0;
}

.successMessage {
  background-color: #20bdff;
  color: #fff;
  padding: 0.625rem;
  border-radius: 0.375rem;
  margin: 1rem auto 0;
  font-family: 'robotoFlexVar';
}
