.btn {
    color: #fff;
    text-decoration: none;
    font-family: 'robotoFlexVar';
    font-size: 1.125rem;
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.btn:hover {
    text-decoration: underline;
  }