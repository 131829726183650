.socials,
.socialsReverse {
  display: flex;
  align-items: center;
  background-color: none;
}

.socials {
  justify-content: flex-end;
}

.socialsReverse {
  justify-content: flex-start;
}

.link {
  height: 32px;
  margin: 16px 16px 16px 0;
}

.link:hover {
  transform: scale(1.2);
}

.img {
  height: 100%;
  width: auto;
}
