.bio,
.bioAlt {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.25rem;
  box-sizing: border-box;
}

.img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1.25rem;
}

.img img {
  max-height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.names {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}

.name {
  font-size: 1.2rem;
  font-weight: bold;
}

.username {
  margin-left: 0.625rem;
  font-size: 1rem;
  color: gray;
}

.body {
  white-space: pre-line;
  vertical-align: bottom;
  text-align: start;
  width: 95%;
}

.socials,
.socialsReverse {
  margin-top: 0.625rem;
  filter: #20bdff;
  justify-content: center;
}

.socialsReverse {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .bio,
  .bioAlt {
    display: flex;
    align-items: center;
  }

  .bio {
    flex-direction: column;
  }

  .bioAlt {
    flex-direction: column-reverse;
  }

  .img {
    margin-bottom: 1.25rem;
  }
}
