.post {
  display: flex;
  margin: 0 0 1rem 1rem;
}

.postVert {
  align-items: center;
  justify-content: center;
}
.postVert .link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img {
  flex: 0 0 12.5rem;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.postVert .img {
  max-width: 25rem;
  height: auto;
  margin: 0;
}

.img img,
.postVert .img img {
  max-width: 100%;
  height: auto;
}

.postVert .img img {
  border-radius: 15px;
}

.postInfo {
  font-family: "robotoFlexVar";
  flex: 1;
}

.postVert .postInfo {
  align-items: center;
}

.postVert .postInfo h2 {
  text-align: center;
}

.postInfo :link {
  text-decoration: none;
}

.postInfo :link:visited {
  text-decoration: none;
}

.postInfo .title {
  text-decoration: none;
  color: #fff;
  margin: 0;
}

.postInfo .summary {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.postVert h2 .title{
  margin-top: 0;
  margin-bottom: 1rem;
  margin-left: 0;
  font-size: 1.5rem;
}

.summary {
  margin-bottom: 1rem;
}

.author {
  font-style: italic;
  color: #666;
  margin: 0.5rem 0;
}

.time {
  color: gray;
}

.link,
.link:visited {
  text-decoration: none;
}
