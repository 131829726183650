.container {
  position: relative;
  display: inline-block;
}

.dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  margin-top: 1rem;
  background-color: #20bdff;
}

.optionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-size: 1.125rem;
  font-family: "robotoFlexVar";
  color: #fff;
}

.arrow {
  font-size: 1rem;
}
