.modalBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* put it on top of other content */
    border-radius: inherit;
  }
  
  .modal {
    width: 80%;
    max-width: 37.5rem;
    padding: 1.25rem;
    background: white;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.26);
    border-radius: 0.625rem;
    z-index: 1001; /* put it on top of the backdrop */
    display: flex;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .modal {
        width: 95%;
        margin: 1rem;
    }
}