.container {
    background-color: transparent;
    color: #fff;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .li {
    margin-right: 1rem;
  }
  
  .link,
  .link:visited {
    color: #fff;
    text-decoration: none;
    font-family: "robotoFlexVar";
    font-size: 1.125rem;
    font-weight: 400;
  }
  
  .link:hover {
    text-decoration: underline;
  }