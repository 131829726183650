.editBtn {
    width: fit-content;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'robotoFlexVar';
    font-size: 1.125rem;
    margin: 0.375rem 0 0.625rem;
    align-items: center;
}

.editIcon {
    max-width: 3.125rem;
    font-size: 1.5rem;
    color: #fff;
    margin-right: 0.375rem;
}

.editBtnText {
    color: #fff;
}