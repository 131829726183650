.container {
  display: flex;
  justify-content: center;
  margin: 1.25rem 0;
}

.text {
  white-space: pre-line;
  vertical-align: bottom;
  text-align: start;
  font-family: "robotoFlexVar";
  font-size: 1.25rem;
  font-weight: 400;
  width: 95%;
  margin: 0;
  margin-left: 1.25rem;
  padding: 0 1rem 0 0 ;
  top: 0;
  left: 0;
  right: 0;
}
