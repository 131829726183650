.progressBar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.25rem;
}

.container {
    background-color: #f5f5fa;
    height: 2rem;
    width: 60%;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    padding: 0.25rem;
    box-shadow: 0.375rem 0.375rem 0 0.125rem #005180;
}

.progress {
    background-color: #20bdff;
    height: 100%;
    width: var(--progress-width, 0%);
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: right;
}

.label {
    padding: 0.625rem;
}

.goal {
    margin-left: 0.625rem;
}