.adminmenu {
    position: relative;
    z-index: 9999;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #20bdff;
    z-index: -1;
}

.AdminOptions {
    z-index: 1;
}