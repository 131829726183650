.container {
    display: flex;
    align-items: center;
    font-family: 'robotoFlexVar';
}

.fraction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
}

.line {
    width: 100%
}

.text {
    padding-left: 1rem;
}