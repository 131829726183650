.container {
  position: relative;
  width: 70%;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto 0.625rem;
  padding: 1.25rem;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-shadow: 0.375rem 0.375rem 0 0.25rem #005180;
}

.poll {
    width: 100%;
}

.container h2,
.container h3,
.container p {
  font-family: "robotoFlexVar";
  color: #333;
  margin: 0 0 0.625rem 0;
}

.voted p {
    text-align: center;
}

.voted h2 {
    margin: 0;
    text-align: center;
}

.options {
    margin-bottom: 0.625rem;
}

.option {
    display: flex;
    align-items: center;
    margin-bottom: 0.375rem;
}

.option label {
    color: #333;
    margin-left: 0.375rem;
}

.link,
.link:hover {
  color: #20bdff;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  transform: scale(1.05);
}

.btn {
  display: block;
  width: 100%;
  padding: 0.625rem;
  background-color: #20bdff;
  color: #fff;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
}

.btn:hover {
    transform: scale(1.02);
}

.container .message {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
