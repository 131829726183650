.intro {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 1200px;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}

.container {
    width: 100%;
}

.subheading {
    color: inherit;
}

.intro p {
    line-height: 1.5;
    font-weight: 400;
    font-size: 1.125rem;
    padding: 0 1rem;
}

@media (max-width: 768px) {
    .intro p {
        font-size: 1rem; /* Reduce the font size on smaller screens */
    }
}