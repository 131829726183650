.footer {
    background-color: #20bdff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
}

.leftSide {
    display: flex;
    align-items: center;
}

.rightSide {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.copy {
    color: #fff;
}

.socials {
    color: #fff;
}

@media (max-width: 768px) {
    .footer {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .leftSide {
        justify-content: center;
        width: 100%;
        margin: 0.5rem auto;
    }
    }