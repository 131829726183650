.prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    font-family: 'robotoFlexVar';
    font-size: 1.5rem;
    font-weight: 500;
    margin: 1.25rem 0;
}