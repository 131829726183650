.dropdown {
  position: absolute;
  left: 0;
  top: 4rem; /*67.5px if this is bad*/
  background-color: #20bdff;
}

.navbtn {
  background: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.navbtn img {
  height: 2rem;
  width: auto;
}