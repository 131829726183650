.btn {
    background: none;
    border: none;
    cursor: pointer;
  }

.btn:hover {
  transform: scale(1.05);
}
  
  .link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
  
  .link img {
    height: 2rem;
    width: auto;
  }
  
  @media (max-width: 768px) {
    .link img {
      height: 1.5rem; /* Adjusting image height for smaller screens */
    }
  }