.link {
    text-decoration: none;
}

.backBtn {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    min-width: 3rem;
    min-height: 1rem;
}

.backBtn:hover {
    transform: scale(1.05);
}