.appHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #20bdff;
}

.navBtn {
  margin-right: 1rem;
  display: inline-block;
}

.leftSide {
  display: flex;
}

.rightSide {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
}

.homeBtn {
  margin-left: 1rem;
}

.createLink {
  margin: 0 1rem;
  background-color: none;
  color: #fff;
  text-decoration: none;
  font-family: "robotoFlexVar";
  font-size: 1.125rem;
}

.accActions {
  margin-left: 1rem;
}

.donateBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  box-shadow: 0.25rem 0.25rem 0 0.1rem #005180;
}

.hashlink {
  color: #333;
  text-decoration: none;
  font-family: 'robotoFlexVar';
  font-size: 1rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  .rightSide {
    flex-direction: column;
  }

  .donateBtn {
    margin-right: 0;
  }
}