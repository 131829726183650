.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #20bdff;
}

footer {
  margin-top: auto;
}

button:hover {
  transform: scale(1.2);
}

div #container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

div,
p,
h1,
h2 {
  font-family: "robotoFlexVar";
  color: #fff;
}

p,
.body {
  white-space: pre-line;
  vertical-align: bottom;
  text-align: start;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 0;
}

.body {
  text-align: start;
  font-weight: 400;
  font-size: 1.125rem;
  width: 90%;
}

#support {
  margin-top: 0;
}

#supportQuestions {
  margin-top: 0;
}

h1 {
  font-size: 2rem;
  margin-left: 1.25rem;
  color: #fff;
}

.homeTitle {
  font-size: 2.5rem;
  text-align: center;
  margin: 0;
}

.tagline {
  text-align: center;
  margin-left: 0;
  padding: 0 1rem;
}

.homeSubheadingWEmoji {
  display: flex;
  align-items: center;
}

h2 {
  font-size: 1.5rem;
  font-weight: 500;
}

.pubdocscta {
  margin-left: 1.25rem;
  padding-right: 1.25rem;
}

.subheading {
  margin-left: 2rem;
}

@media (max-width: 768px) {
  .subheading {
    margin-left: 1.25rem;
  }
}

.hashLink,
.hashLink:visited {
  color: #fff;
  text-decoration: underline;
  font-family: "robotoFlexVar";;
}

.centeredSub {
  margin: 0;
}

.arrow {
  margin-bottom: 0.625rem;
}

.paymentButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1.25rem;
  margin-bottom: 1.25rem;
}

.payment {
  display: inline-block;
  border: none;
  background-color: #f5f5fa;
  cursor: pointer;
  padding: 1.25rem;
  border-radius: 1.25rem;
  box-shadow: 0.375rem 0.375rem 0 0.25rem #005180;
  margin: 1rem;
}

.payment:hover {
  transform: scale(1.05);
}

.payment a {
  display: block;
  height: 100%;
  width: 100%;
}

.payment img {
  max-width: 100%;
  max-height: 100%;
}

#venmo {
  margin-right: 1.25rem;
}

#venmo img {
  height: 3.75rem;
  width: auto;
}

#paypal {
  margin-left: 1.25rem;
}

#paypal img {
  height: 3.75rem;
  width: auto;
}

.iconWText {
  width: 100%;
  height: auto;
  margin: 0;
}

.register,
.login,
.homeRegisterButton {
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  margin: 0 auto;
  padding: 1.25rem;
  border: 1px solid #ccc;
  border-radius: 1.25rem;
  background-color: #f9f9f9;
  box-shadow: 0.375rem 0.375rem 0 0.25rem #005180;
}

.homeRegisterButton {
  color: #20bdff;
  font-family: 'robotoFlexVar';
  font-weight: 600;
  margin-bottom: 1.25rem;
}

.homeRegisterButton .link,
.homeRegisterButton .hashLink:visited {
  color: #20bdff;
  text-decoration: none;
  cursor: pointer;
}

.homeRegisterButton:hover {
  transform: scale(1.2);
}

.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cta .item {
  margin-bottom: 0.625rem;
}

.item {
  margin: 0.625rem;
  max-width: 85%;
  text-align: center;
}

.arrow svg {
  height: 3.125rem;
}

.name-fields {
  display: flex;
  justify-content: space-between;
}

.name-fields input {
  flex: 1;
  margin-right: 0.625rem;
}
.name-fields .lastName {
  margin-right: 0;
}

.register input,
.login input {
  max-width: 100%;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
}

.register button,
.login button,
.blogForm button,
.pollForm button {
  display: block;
  width: 100%;
  padding: 0.625rem;
  background-color: #20bdff;
  color: #fff;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
}

.blogForm,
.pollForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 1.25rem;
  border: 1px solid #ccc;
  border-radius: 1.25rem;
  background-color: #f9f9f9;
  box-shadow: 0.375rem 0.375rem 0 0.25rem #005180;
}

.blogPreviewInfo,
.pollInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.blogPreviewInfo input,
.pollForm input {
  max-width: 100%;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  color: #333;
}

.blogForm .editor,
.pollForm .editor {
  width: 100%;
  margin-bottom: 0.625rem;
}

.quill {
  border-radius: 0.375rem;
  width: 100%;
}

.ql-toolbar {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.ql-snow .ql-picker{
  position: relative;
  z-index: 9998;
}

.ql-container {
  height: auto;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.ql-editor p {
  color: #333;
}

.blogContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backBtnContainer {
  display: flex;
}

.backBtnContainer a {
  margin-left: 1.25rem;
}

.blogTitle {
  margin: 0;
}

.lineIconContainer {
  display: flex;
  max-width: 80%;
  height: auto;
  margin: 0 auto;
  align-items: center;
  box-sizing: border-box;
}

.lineIcon {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.aboveImg {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
}

.vLine {
  margin-left: 0.375rem;
}

.blogAuthor {
  margin-left: 0.375rem;
}

.blogImage {
  max-width: 80%;
  margin: 0 auto; 
}

.blogImage img {
  max-height: 37.5rem;
  max-width: 100%;
  height: auto;
  width: auto;
  border-radius: 1.25rem;
}

.blogContent {
  max-width: 60%;
  align-self: center;
  text-align: center;
}

form button:hover {
  transform: scale(1.02);
}

.pollForm h3,
.register h3,
.login h3 {
  margin: 0 0 0.625rem 0;
  color: #333;
}

.pollForm .pollHeader {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.pollOptions {
  display: block;
  width: 100%;
}

.optionGroup {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}

.optionGroup input {
  flex: 4;
  margin-bottom: 0;
  margin-right: 0.625rem;
}

.optionGroup button {
  flex: 1;
  flex-shrink: 0;
}

.addOptionBtn {
  margin-bottom: 0.625rem;
  width: 50%;
}

.poll {
  margin-top: 1.25rem;
}

.ul {
  margin-left: 2rem;
}

.link,
.link:visited {
  font-family: 'robotoFlexVar';
  color: #fff;
  cursor: pointer;
}

.link:hover {
  transform: scale(1.1);
}