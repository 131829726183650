.faq {
    margin: 0 3.75rem;
    max-width: 90%;
}

.question {
    cursor: pointer;
}

.answer {
    margin-left: 1.25rem;
}

/* Mobile view */
@media (max-width: 768px) {
    .faq {
        margin: 0 1rem; /* Adjusted margin for smaller screens */
        max-width: 100%; /* Adjusted max-width for smaller screens */
    }
}
